var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keepJiesuan" },
    [
      _c(
        "van-pull-refresh",
        {
          attrs: { "success-text": "刷新成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "cell",
                  on: {
                    click: function($event) {
                      return _vm.ToStockBalance(item)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      style: {
                        backgroundColor:
                          item.orderType == "MORTGAGE" ? "#1a73e8" : "#feb706"
                      }
                    },
                    [_vm._v(_vm._s(item.orderType == "MORTGAGE" ? "尾" : "预"))]
                  ),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("p", { staticClass: "totalWeight" }, [
                        _vm._v(
                          "来料总重 : " +
                            _vm._s(item.incomingWeight.toFixed(2)) +
                            "g"
                        )
                      ]),
                      _c("p", { staticClass: "DiscountWeight" }, [
                        _vm._v(
                          "折足重量 : " + _vm._s(item.weight.toFixed(2)) + "g"
                        )
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDate_")(item.created)))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "right",
                        style: {
                          color:
                            item.payStatus == "WAIT_CONFIRM"
                              ? "red"
                              : item.payStatus == "WAIT"
                              ? "#feb706"
                              : "#ccc"
                        }
                      },
                      [
                        _c("div", { staticClass: "config" }, [
                          _vm._v(
                            _vm._s(
                              item.payStatus == "WAIT_CONFIRM"
                                ? "待确认"
                                : item.payStatus == "PAYED"
                                ? "已收款"
                                : "待收款"
                            )
                          )
                        ]),
                        _c("div", { staticClass: "money" }, [
                          _vm._v(
                            _vm._s(
                              item.fixedRemainMoney
                                ? item.fixedRemainMoney > 0
                                  ? "+" + item.fixedRemainMoney.toFixed(2)
                                  : item.fixedRemainMoney.toFixed(2)
                                : item.money > 0
                                ? "+" + item.money.toFixed(2)
                                : item.money
                            )
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("van-icon", { attrs: { name: "arrow" } })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }