var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keepRecord" },
    [
      _c(
        "van-tabs",
        {
          attrs: { animated: "", sticky: "" },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: "买料定价" } },
            [_c("keep-alive", [_c("keepAdmin")], 1)],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "买料结算" } },
            [_c("keep-alive", [_c("keepJiesuan")], 1)],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabsLeftIcon", on: { click: _vm.ToPerson } },
        [_c("van-icon", { attrs: { name: "arrow-left" } })],
        1
      ),
      (_vm.active == "1"
      ? false
      : true)
        ? _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "startTime" }, [_vm._v("开始时间")]),
            _c("div", { staticClass: "weight" }, [_vm._v("重量(g)")]),
            _c("div", { staticClass: "realTlmeMOney" }, [
              _vm._v("实时应收(元)")
            ]),
            _c("div", { staticClass: "state" }, [_vm._v("状态")])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }